import React, { useContext, useState, useEffect } from "react";
import { getAuth } from "firebase/auth";
import { StateContext } from "../context";
import { Link, useLocation } from "react-router-dom";

//import MenuIcon from '@mui/icons-material/Menu';
import GroupIcon from '@mui/icons-material/Group';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';

import { fetchUserProfileById } from "../firebase/services/userService";
import EditAthleteModal from "./editAthleteModal";

import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';

export default function Nav() {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) throw new Error('No authenticated user found');

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [userObject, setUserObject] = useState({} as any);
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [clicksOutside, setClicksOutside] = useState(0);
  const [signOutModalOpen, setSignOutModalOpen] = useState(false);
  const [userIdForEditModal, setUserIdForEditModal] = useState("");

  const updateUserObject = async () => {
    console.log("Fetching user data...", user.uid, "for", user.displayName || "unknown user");
    if (!user.uid) return;
    let userData = await fetchUserProfileById(user.uid);
    console.log(userData);
    setUserObject(userData);
  }

  useEffect(() => {
    user.uid && updateUserObject();
  }, [user.uid]);

  const sideNavItem = (icon: JSX.Element, text: string, route: string[]) => {
    let active = false;
    if (location.pathname === "/activity" && searchParams.get("uid") && route[0] === `/activity?uid=${userObject.userId}`) active = searchParams.get("uid") === userObject.userId;
    else active = route.includes(location.pathname);
    return (
      <Link to={route[0]} className={`${active ? "bg-white text-black" : "hover:bg-white hover:text-black"} rounded-lg px-3 md:px-4 py-2 flex items-start gap-2`}>
        {icon}
        <h2 className="font-semibold shrink-0">{text}</h2>
      </Link>
    );
  }

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      // If modal is open and click is outside of modal, close modal
      let modal = document.querySelector('#signoutModal');
      if (modal && !(e.target as HTMLElement).closest('.card')) {
        setClicksOutside(prevClicks => prevClicks + 1);
      }
    }

    document.addEventListener('click', handleClick);
    
    // Cleanup function
    return () => document.removeEventListener('click', handleClick);
  }, []);

  useEffect(() => {
    if (clicksOutside > 1) {
      setSignOutModalOpen(false);
      setClicksOutside(0);
    }
  }, [clicksOutside]);

  return (
    <>
      {userIdForEditModal && <EditAthleteModal idOfAthleteToBeEdited={userIdForEditModal} setIdOfAthleteToBeEdited={setUserIdForEditModal} refreshAthleteList={updateUserObject} />}

      {signOutModalOpen && (
        <div id="signoutModal" className="fixed top-0 left-0 flex items-center justify-center h-screen w-screen " style={{background: "rgba(0, 0, 0, 0.85)", zIndex: "99"}}>
          <div className="card p-8 flex flex-col items-center gap-4">
            <h2 className="text-2xl font-bold">Sign Out</h2>
            <p>Are you sure you want to sign out?</p>

            <Link to="/signout" className="rounded-lg bg-mfx-blue text-white py-2 w-full text-center">Confirm</Link>
            <button className="rounded-lg border border-white py-2 w-full" onClick={() => {
              setSignOutModalOpen(false);
              setClicksOutside(0);
            }}>Cancel</button>
          </div>
        </div>
      )}

      <nav className="bg-mfx-blue flex flex-col gap-4 py-4 text-white border-r border-white p-4 md:px-6 md:py-8">
        <div className="flex items-center gap-2 md:mb-8">
          <img src="/main_logo.png" alt="MoveFactorX" className="h-12" />
        </div>

        {sideNavItem(<GroupIcon />, "Dashboard", ["/", "/team"])}
        {sideNavItem(<PersonIcon />, "My Activity", [`/activity?uid=${userObject.userId}`])}

        <div 
          className="cursor-pointer flex items-center gap-2 mt-auto"
          onClick={() => setSignOutModalOpen(true)}
        >
          <img
            onClick={() => setDropDownOpen(!dropDownOpen)}
            src={user.photoURL || "/user_placeholder.png"} 
            alt="profile" 
            className="w-8 h-8 rounded-full cursor-pointer "
          />

          <p className="shrink-0 font-bold">{user.displayName}</p>
          {dropDownOpen && (
            <div 
              className="absolute top-12 right-0 bg-[#333333] rounded-lg py-2 px-4 border border-white"
              style={{width: "200px"}}
            >
              <h2 className="text-white mb-2 shrink-0">{user.displayName}</h2>
              <button className="flex items-center gap-2 w-full py-2" onClick={() => setUserIdForEditModal(userObject.id)}>
                <PersonIcon />
                <span className="shrink-0">Edit Profile</span>
              </button>
              <button className="flex items-center gap-2 w-full py-2" onClick={() => {
                setDropDownOpen(false);
                setSignOutModalOpen(true)}
              }>
                <LogoutIcon />
                <span>Sign Out</span>
              </button>
            </div>
          )}
        </div>
      </nav>
      <div style={{ height: "64px"}} />
    </>
  );
}