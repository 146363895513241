import { db } from '../firebase_config';
import { collection, query, where, getDocs, limit } from 'firebase/firestore';
import { getAuth } from "firebase/auth";
import { Workout, ExerciseSet } from '../../models';

export const fetchWorkoutsByUserId = async (userId: string, maxWorkouts: null | number): Promise<Workout[]> => {
  const user = getAuth().currentUser;
  
  if (!user) { throw new Error('No authenticated user found')}

  const coachId = user.uid;
  //console.log("Fetching with athleteUserId:", userId);
  const workoutsCollection = collection(db, 'Workouts');

  // Use the coachId in the query to ensure the createdBy field matches the currently logged-in user's ID
  let q = query(
    workoutsCollection, 
    where('athleteUserId', '==', userId),
    where('createdBy', '==', coachId)
  );

  if (maxWorkouts !== null) {
    q = query(q, limit(maxWorkouts));
  }

  const querySnapshot = await getDocs(q);

  const workouts: Workout[] = querySnapshot.docs.map(docSnapshot => {
    const data = docSnapshot.data();
    //console.log(data);
    const workout: Workout = {
      createdAt: new Date(data.createdAt.seconds * 1000 + data.createdAt.nanoseconds / 1000000),
      createdBy: data.createdBy,
      reps: data.Reps,
      refId: data.refId,
      workoutMode: data.workoutMode,
      workoutProtocol: data.workoutProtocol,
    };
    return workout;
  });

  return workouts;
};

export const fetchExerciseSetsByWorkoutId = async (workoutId: string): Promise<ExerciseSet[] | null> => {
  const exerciseSetsCollection = collection(db, `Workouts/${workoutId}/ExerciseSets`);
  const exerciseSetsSnapshot = await getDocs(exerciseSetsCollection);
  
  const exerciseSets = exerciseSetsSnapshot.docs.map(exerciseSetDoc => {
    return { ...exerciseSetDoc.data(), refId: exerciseSetDoc.id } as ExerciseSet;
  });
  
  if (exerciseSets.length === 1 && !exerciseSets[0].Reps) {
    return null
  }

  return exerciseSets;
};
