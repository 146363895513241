import React, { useState, useEffect } from "react";
import { workoutProtocols, imageForWorkoutProtocol } from "../constants";

interface Props {
  selectedExercise: string;
  setSelectedExercise: (exercise: string) => void;
  setModalVisible: (boolean: boolean) => void;
}

const ExerciseSelect: React.FC<Props> = ({ selectedExercise, setSelectedExercise, setModalVisible }) => {
  const [tab, setTab] = useState(workoutProtocols[selectedExercise].movementType);
  const [clicksOutside, setClicksOutside] = useState(0);
  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      const calendar = document.querySelector('.exercise-select');
      if (calendar && !calendar.contains(e.target as Node)) {
        console.log('Clicked outside calendar');
        setClicksOutside(prevClicks => prevClicks + 1);
      }
    }

    document.addEventListener('click', handleClick);
    
    // Cleanup function
    return () => document.removeEventListener('click', handleClick);
  }, []);

  useEffect(() => {
    if (clicksOutside > 1) setModalVisible(false);
  }, [clicksOutside, setModalVisible]);

  const tabComponent = (tabName: string) => {
    return (
      <button
        className={`${tab === tabName ? "underline underline-offset-2 font-bold" : ""}`}
        onClick={() => setTab(tabName)}
      >
        {tabName}
      </button>
    );
  }
  
  return (
    <div className="absolute top-8 left-0 card p-4 z-10 max-w-[630px] exercise-select">
      <div className="flex items-center gap-6 border-b border-[#6f6f6f] pb-1">
        {["Full Body", "Upper Body", "Lower Body"].map(tabName => tabComponent(tabName))}
      </div>

      <div className="flex flex-wrap gap-4 mt-4">
        {Object.keys(workoutProtocols)
          .filter(exercise => workoutProtocols[exercise].movementType === tab)
          .map(exercise => {
            return (
              <div
                key={exercise}
                onClick={() => setSelectedExercise(exercise)}
                className={`${selectedExercise === exercise ? "bg-[#D6D6D6] text-[#444444]" : "cursor-pointer hover:bg-[#D6D6D6] hover:text-[#444444]"} flex flex-col items-center justify-start p-2 gap-2 w-28 rounded-lg`}
              >
                <img src={`exercise_icons/${imageForWorkoutProtocol(exercise)}.png`} alt={exercise} className="w-12 h-12 rounded-full" />
                <p className="text-center">{exercise}</p>
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default ExerciseSelect;