import React, { useEffect, useState, useContext, useRef } from "react";
import useUserTeams from "../hooks/useUserTeams";
import { StateContext } from "../context";
import { Team, UserProfile } from "../models";
import { fetchUserProfileById, fetchMultipleUserProfilesByIds } from "../firebase/services/userService";

import NewTeamModal from "../components/newTeamModal";
import AthleteCard from "../components/athleteCard"
import TeamCard from "../components/teamCard";

import AddIcon from '@mui/icons-material/Add';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import GridViewIcon from '@mui/icons-material/GridView';

const Dashboard = () => {
  const { userIsCoach, hasDoneFetch } = useContext(StateContext);
  if (hasDoneFetch && !userIsCoach) window.location.href = "/activity";

  const [displayedAthleteIds, setDisplayedAthleteIds] = useState([] as UserProfile[]);
  const [idsOfAllTeamsUsers, setIdsOfAllTeamUsers] = useState([] as UserProfile[]);
  const [displayedAthleteCount, setDisplayedAthleteCount] = useState(12);
  const [searchQuery, setSearchQuery] = useState("" as string);
  const [fetching, setFetching] = useState(false);
  const [newTeamModalOpen, setNewTeamModalOpen] = useState(false);
  const [tab, setTab] = useState("Teams");
  const teams = useUserTeams() as Team[];
  console.log(teams);
  const tabs = ["Athletes", "Teams"];

  const observer = useRef<IntersectionObserver | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    async function setIds(teams: Team[]) {
      setFetching(true);
      let athleteInfo: UserProfile[] = [];
      
      const mappedUsers = teams.flatMap(team => 
        (team.users ?? []) // Ensure users is always an array, even if undefined
          .filter(user => Boolean(user)) // Filter out any undefined or falsy user IDs
          .map(user => ({ "id": user, "team": team.name, }))
      );

      const userIds = mappedUsers.map(user => user.id);
      const uniqueUserIds = Array.from(new Set(userIds));

      const userProfiles = await fetchMultipleUserProfilesByIds(uniqueUserIds);

      console.log(athleteInfo);
      setFetching(false);
      setIdsOfAllTeamUsers(userProfiles);
    };
  
    teams.length > 0 && setIds(teams);
  }, [teams]);

  const [viewMode, setViewMode] = useState("Cards");
  const [sortBy, setSortBy] = useState("Athletes");

  const tabElements = () => {
    return tabs.map((tabName) => {
      return (
        <button
          key={tabName}
          className={`${(tab === tabName) ? "border-[#333333] font-bold" : "border-white"} text-lg px-2 border-b-4`}
          onClick={() => {
            setSortBy("Athletes");
            setSearchQuery("");
            setTab(tabName)
          }}
        >
          {tabName}
        </button>
      );
    });
  };

  useEffect(() => {
    if (fetching) return;
    
    observer.current = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting && displayedAthleteIds.length >= 12) setDisplayedAthleteCount(prevCount => prevCount + 12);
      });
    }, { root: null, rootMargin: '0px', threshold: 1.0 });

    const sentinel = containerRef.current?.querySelector('#sentinel');

    if (sentinel) observer.current.observe(sentinel);
    else console.error("Sentinel not found");

    return () => {
      if (sentinel && observer.current) {
        observer.current.unobserve(sentinel);
      }
      observer.current?.disconnect();
    };
  }, [displayedAthleteIds.length, setDisplayedAthleteCount, tab, fetching]);

  const handleSort = (sortBy: string, arrayToSort: any, searchQuery: string) => {
    let sortedArray = arrayToSort.sort((a: any, b: any) => {
      switch (sortBy) {
        case "Athletes":
          return 0;
        case "Members":
          return (b.users ? b.users.length : 0) - (a.users ? a.users.length : 0); 
        case "Name":
          return a.name.localeCompare(b.name);
        default:
          return 0;
      }
    })

    if (searchQuery) {
      sortedArray = sortedArray.filter((item: any) => {
        if (!item.name) return false;
        return item.name.toLowerCase().includes(searchQuery.toLowerCase());
      });
    }

    return sortedArray
  };

  useEffect(() => {
    let idsToDisplay = [...idsOfAllTeamsUsers];
    idsToDisplay = idsToDisplay.slice(0, displayedAthleteCount);
    console.log(idsOfAllTeamsUsers);
    setDisplayedAthleteIds(handleSort(sortBy, idsToDisplay, searchQuery));
  }, [displayedAthleteCount, idsOfAllTeamsUsers, sortBy, searchQuery]);

  return (
    <>
      {newTeamModalOpen && <NewTeamModal setTeamModal={setNewTeamModalOpen} />}

      <div className="bg-white px-6 pt-12 sticky top-0">
        <div className="container mx-auto px-4">
          <h1 className="text-2xl font-bold">Dashboard</h1>

          <div className="flex items-center gap-4 mt-6">
            {tabElements()}
          </div>
        </div>
      </div>

      <div className="container mx-auto px-4 py-6">
        <div className="flex items-center gap-4">

          <div className="flex items-center gap-2 pl-4 rounded-md border-white bg-white w-full max-w-[450px]">
            <SearchIcon />
            <input 
              value={searchQuery} 
              type="text" 
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder={`Search ${tab === "Teams" ? "Teams" : "Athletes"}...`} 
              className="py-2 pr-4 bg-transparent outline-none w-full" />
          </div>

          <div className="flex items-center gap-2 md:ml-auto">
            <p className="shrink-0">Sort by:</p>
            <select
              className="bg-transparent w-full py-2 outline-none cursor-pointer"
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value)}
            >
              <option value="Athletes">Athletes</option>
              <option value="Name">Name</option>
              {tab === "Teams" && <option value="Members">Team Size</option>}
            </select>
          </div>

          <button onClick={() => setNewTeamModalOpen(true)} className="px-3 py-2 card"><AddIcon />&nbsp;Create Team</button>

          <div className="flex items-center gap-1 px-2 py-1 card">
            <button
              className={`px-3 py-1 rounded-lg ${viewMode === "Cards" ? "bg-[#eef2f8]" : ""}`}
              onClick={() => setViewMode("Cards")}
            >
              <GridViewIcon />
            </button>
            <button
              className={`px-3 py-1 rounded-lg ${viewMode === "List" ? "bg-[#eef2f8]" : ""}`}
              onClick={() => setViewMode("List")}
            >
              <MenuIcon />
            </button>
          </div>
        </div>

        {tab === "Athletes" && (
          fetching
            ? <div className="flex items-center flex-col items-center justify-center gap-6 py-6 mt-24 w-full">
                <i className="fas fa-spinner fa-spin text-6xl"></i>
                <h3 className="font-medium text-2xl">Fetching Athlete Data...</h3>
              </div>
            : viewMode === "Cards"
              ? <div ref={containerRef} className="grid md:grid-cols-2 lg:grid-cols-4 gap-6 mt-4">
                  {displayedAthleteIds.map(user => (
                    <AthleteCard
                      key={user.id}
                      athleteInfo={user}
                      renderAs="Card"
                    />
                  ))}
                  <div id="sentinel" style={{ height: '1px' }}></div>
                </div>
              : <>
                  <table className="table-auto w-full mt-4">
                    <thead>
                      <tr>
                        <th className="text-left pl-8">Athletes</th>
                        <th>Team</th>
                        <th>Speed</th>
                        <th>Force</th>
                        <th>Power</th>
                        <th>Pop100</th>
                        <th>Pop200</th>
                        <th>EI100</th>
                        <th>EI200</th>
                      </tr>
                    </thead>
                    <tbody>
                      {displayedAthleteIds.map((user) => {
                        return (
                          <AthleteCard 
                            athleteInfo={user}
                            renderAs="List"
                          />
                        );
                      })}
                    </tbody>
                  </table>
                  <div id="sentinel" style={{ height: '1px' }}></div>
                </>
        )}

        {tab === "Teams" && (
          viewMode === "Cards"
          ?  <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-6 mt-4">
                {handleSort(sortBy, teams, searchQuery).map((team: Team) => (
                  <TeamCard
                    id={team.id}
                    name={team.name}
                    renderAs="Card"
                    description={team.description ? team.description : "-"}
                    imageURL={team.TeamImageURL ? team.TeamImageURL : "/user_placeholder.png"}
                    numberOfMembers={team.users ? team.users.length : 0}
                  />
                ))}
              </div>
          : <table className="table-auto w-full mt-4">
              <thead>
                <tr>
                  <th className="text-left pl-2">Team</th>
                  <th className="text-left">Description</th>
                  <th className="text-left">Members</th>
                </tr>
              </thead>
              <tbody>
                {handleSort(sortBy, teams, searchQuery).map((team: Team) => (
                  <TeamCard
                    id={team.id}
                    name={team.name}
                    renderAs="List"
                    description={team.description ? team.description : "-"}
                    imageURL={team.TeamImageURL ? team.TeamImageURL : "/user_placeholder.png"}
                    numberOfMembers={team.users ? team.users.length : 0}
                  />
                ))}
              </tbody>
            </table>
        )}
      </div>
    </>
  );
}

export default Dashboard;