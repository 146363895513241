import React, { useState, useEffect } from "react";
import { createTeam } from "../firebase/services/teamService";
import { getAuth } from "firebase/auth";

type NewTeamModalProps = {
  setTeamModal: (value: boolean) => void;
};

const NewTeamModal: React.FC<NewTeamModalProps> = ({ setTeamModal }) => {
  const [teamName, setTeamName] = useState("");
  const [teamDescription, setTeamDescription] = useState("");
  const [attemptingTeamCreate, setAttemptingTeamCreate] = useState(false);
  const [errorCreatingTeam, setErrorCreatingTeam] = useState(false);

  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) throw new Error('No authenticated user found');

  useEffect(() => {
    console.log("New team modal mounted");
  }, []);

  const attemptTeamCreate = async (teamName: string, teamDescription: string) => {
    setAttemptingTeamCreate(true);
    try {
      const newTeamId = await createTeam(teamName, teamDescription, user.uid);
      newTeamId ? window.location.href = `/team?id=${newTeamId}` : setErrorCreatingTeam(true);
    } catch (error) {
      setErrorCreatingTeam(true);
      console.error("Failed to create team", error);
    }
    setAttemptingTeamCreate(false);
  }

  return (
    <div className="modal">
      <div className="card p-6 flex flex-col">
        <h1 className="text-2xl font-bold text-center">Create New Team</h1>

        <form onSubmit={(e) => {
          e.preventDefault();
          attemptTeamCreate(teamName, teamDescription);
        }}>
          <p className="mt-6">Team Name:</p>
          <input
            type="text"
            required={true}
            placeholder="Team name"
            value={teamName}
            onChange={(e) => setTeamName(e.target.value)}
            className="py-1 px-2 bg-transparent outline-none border border-white rounded-lg w-full"
          />

          <p className="mt-4">Team Description:</p>
          <textarea
            placeholder="Team description"
            required={true}
            value={teamDescription}
            onChange={(e) => setTeamDescription(e.target.value)}
            className="py-1 px-2 bg-transparent outline-none border border-white rounded-lg w-full"
          />

          {errorCreatingTeam && <p className="text-red-500 mt-2">Failed to create team. Please try again.</p>}

          <button
            disabled={attemptingTeamCreate}
            className="bg-white text-black rounded-lg px-6 py-2 font-bold mt-4 w-full">{attemptingTeamCreate ? <i className="fas fa-spinner fa-spin"></i> : "Create team"}</button>
        </form>

        <button
          disabled={attemptingTeamCreate}
          onClick={() => setTeamModal(false)} className="border-2 border-white rounded-lg px-6 py-2 font-bold mt-2">Cancel</button>
      </div>
    </div>
  );
};

export default NewTeamModal;