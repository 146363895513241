import React, { useState, useEffect } from "react";
import { fetchWorkoutsByUserId } from "../firebase/services/workoutService";
import { numberOfWorkoutsForRadarChart } from "../constants";
import { calculateScoresFromWorkouts, getSetsForWorkout, formatScoresIntoRadarData } from "../utils";
import { RadarData, UserProfile } from "../models";

import { Radar, RadarChart, PolarGrid, PolarAngleAxis, ResponsiveContainer } from 'recharts';

type AthleteCardProps = {
  athleteInfo: UserProfile;
  renderAs: "Card" | "List";
};

const AthleteCard: React.FC<AthleteCardProps> = ({ athleteInfo, renderAs }) => {
  console.log("Athlete info for card:", athleteInfo)
  const [failedToFetch, setFailedToFetch] = useState(false);
  const [areaChartData, setAreaChartData] = useState<RadarData[]>([]);
  const [scores, setScores] = useState({} as {
      Speed: number;
      Force: number;
      Power: number;
      Pop100: number;
      Pop200: number;
      EI100: number;
      EI200: number;
  });
  const [fetching, setFetching] = useState(true);

  useEffect(() => {
    const fetchUserData = async () => {
      if (!athleteInfo.id) return;
      try {
        const workoutIds = await fetchWorkoutsByUserId(athleteInfo.id, numberOfWorkoutsForRadarChart);
        const workouts = await getSetsForWorkout(workoutIds, false, "Concentric");
        const scores = calculateScoresFromWorkouts(workouts, "Concentric");
        setScores(scores);
        console.log(formatScoresIntoRadarData(scores))
        setAreaChartData(formatScoresIntoRadarData(scores));
        setFetching(false);
      } catch (error) {
        console.error('Failed to fetch user data', error, athleteInfo.id);
        setFailedToFetch(true);
        // Handle error appropriately
      }
    };

    fetchUserData();

    console.log(athleteInfo)
  }, [athleteInfo]);

  const sendToUserActivityPage = () => window.location.href = `/activity?uid=${athleteInfo.id}`;

  return (
  <>
    {renderAs === "Card" && (
      <div onClick={() => sendToUserActivityPage()} className="card cursor-pointer p-2 md:p-4">
        {/*
        <div className="flex items-center justify-end gap-2">
          <div className="flex items-center gap-1 text-xs" style={{color: statusColors["Active"]}}>
            <CircleIcon fontSize="inherit" />
            <span className="italic" >{status}</span>
          </div>

          <button className="text-sm">
            {favorite ? <span style={{color: "#ffe05f"}}><StarIcon /></span> : <StarBorderOutlinedIcon />}
          </button>
        </div>
        */}

        <div className="flex items-center gap-4 border-b border-[#6f6f6f] pb-2 mt-4">
          <img src={athleteInfo.profileImageURL ? athleteInfo.profileImageURL : "/user_placeholder.png"} alt="athlete" className="w-12 h-12 rounded-full" />

          <div>
            <h3 className="text-lg font-thin">{athleteInfo.displayName}</h3>
            <p className="text-sm">{athleteInfo.team}</p>
          </div>
        </div>

        <div className="mt-4">
          {fetching
            ? <div className="flex items-center flex-col items-center justify-center gap-6 py-6 flex-grow w-full">
                <i className="fas fa-spinner fa-spin text-3xl"></i>
              </div>
            : <ResponsiveContainer width="100%" height={200}>
                <RadarChart cx="50%" cy="50%" outerRadius="80%" data={areaChartData}>
                  <PolarGrid />
                  <PolarAngleAxis dataKey="subject" />
                  <Radar name="Mike" dataKey="A" stroke="#C5C5C5" fill="#1c1f2c" fillOpacity={0.8} />
                </RadarChart>
              </ResponsiveContainer>
          }
        </div>
      </div>
    )}

    {renderAs === "List" && (
      <tr onClick={() => sendToUserActivityPage()} className="cursor-pointer text-center">
        <td>
          <div className="flex items-center gap-2 pl-4">
            <img src={athleteInfo.profileImageURL ? athleteInfo.profileImageURL : "/user_placeholder.png"} alt="athlete" className="w-12 h-12 rounded-full" />

            <h3 className="text-lg font-thin">{athleteInfo.firstName}&nbsp;<span className="font-bold">{athleteInfo.lastName}</span></h3>
          </div>
        </td>
        <td>{athleteInfo.team}</td>
        {scores && Object.keys(scores).map((score: string) => (
          <td key={score}>{scores[score as keyof typeof scores] ? Number(scores[score as keyof typeof scores] * 100).toPrecision(3) : "-"}</td>
        ))}
      </tr>
    )}
  </>)
};

export default AthleteCard;