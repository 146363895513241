import React, { useEffect, useState, useContext, useRef } from "react";
import { Team, UserProfile } from "../models";
import fetchTeamData from "../hooks/useTeamUsers";
import { useLocation } from "react-router-dom";
import { StateContext } from "../context";

import EditAthleteModal from "../components/editAthleteModal";
import NewAthleteModal from "../components/newAthleteModal";
import RemoveUserModal from "../components/removeUserModal";
import AddExistingUserModal from "../components/addExistingUserModal";

import { updateTeamNameAndDescription, deleteTeam } from "../firebase/services/teamService";
import { fetchUserProfileById } from "../firebase/services/userService";
import { handleFileChange } from "../utils";

import AddIcon from '@mui/icons-material/Add';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import GridViewIcon from '@mui/icons-material/GridView';

const TeamPage = () => {
  const { userIsCoach, hasDoneFetch } = useContext(StateContext);
  if (hasDoneFetch && !userIsCoach) window.location.href = "/activity";

  const [teamData, setTeamData] = useState({} as Team);
  const [teamAthletes, setTeamAthletes] = useState([] as UserProfile[]);
  const [teamCoaches, setTeamCoaches] = useState([] as UserProfile[]);
  const [teamName, setTeamName] = useState("");
  const [teamDescription, setTeamDescription] = useState("");
  const [teamImageURL, setTeamImageURL] = useState("");
  const [pendingDelete, setPendingDelete] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState("");
  const [idOfUserToBeRemoved, setIdOfUserToBeRemoved] = useState("");
  const [nameOfUserToBeRemoved, setNameOfUserToBeRemoved] = useState("");
  const [newAthleteModalOpen, setNewAthleteModalOpen] = useState(false);
  const [addExistingUserType, setAddExistingUserType] = useState(null as "Coach" | "Athlete" | null);
  const [idOfAthleteToBeEdited, setIdOfAthleteToBeEdited] = useState("");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  async function fetchTeamInfo(currentTeamId: string) {
    console.log("Fetching team info for team:", currentTeamId);

    try {
      let teamFetch = await fetchTeamData(currentTeamId);
      
      console.log("Team fetch:", teamFetch);

      if (!teamFetch) {
        console.log("Team not found");
        //window.location.href = "/404";
        return;
      };

      let currentTeamData = teamFetch.teamData[0];

      if (currentTeamData.coaches) {
        const coachProfilesPromises = currentTeamData.coaches.map((userId: any) => fetchUserProfileById(userId));
        const coachProfilesResults = await Promise.all(coachProfilesPromises);
        const validCoachProfiles: UserProfile[] = coachProfilesResults.filter((profile): profile is UserProfile => profile !== null);
        console.log("Valid coach profiles:", validCoachProfiles)
        setTeamCoaches(validCoachProfiles);
      }
      
      setTeamData(currentTeamData);
      setTeamName(currentTeamData.name);
      setTeamDescription(currentTeamData.description);
      setTeamAthletes(teamFetch.athletes);
      currentTeamData.TeamImageURL && setTeamImageURL(currentTeamData.TeamImageURL);
      console.log(teamFetch?.teamData, teamFetch?.athletes);
    } catch (error) { 
      console.error("Error fetching team info:", error);
      //window.location.href = "/404";
    }
  }

  useEffect(() => {
    const currentTeamId = searchParams.get("id");  
    currentTeamId ? fetchTeamInfo(currentTeamId) : window.location.href = "/404";
  }, [location.search]);
  
  const [teamFilter, setTeamFilter] = useState(null as string | null);
  const [searchQuery, setSearchQuery] = useState("" as string);
  const [tab, setTab] = useState("Athletes");
  const tabs = ["Athletes", "Coaches", "Settings"];

  const [viewMode, setViewMode] = useState("Cards");
  const [sortBy, setSortBy] = useState("All");

  const tabElements = (teamFilter: string | null) => {
    return tabs.map((tabName) => {
      return (
        <button
          key={tabName}
          className={`${(!teamFilter && tab === tabName) ? "border-[#333333]" : "border-white"} text-lg px-2 border-b-4`}
          onClick={() => {
            setTeamFilter(null);
            setSortBy("All");
            setSearchQuery("");
            setTab(tabName)
          }}
        >
          {tabName}
        </button>
      );
    });
  };

  const refreshAthleteList = async () => await fetchTeamInfo(teamData.id);

  const attemptTeamDelete = async () => {
    console.log("Deleting team:", teamData.id)
    const deleteConfirmation = await deleteTeam(teamData.id);
    console.log("Delete confirmation:", deleteConfirmation)
    if (deleteConfirmation) window.location.href = "/";
  }

  const attemptTeamUpdate = async () => {
    console.log("Updating team:", teamData.id)
    const updateConfirmation = await updateTeamNameAndDescription(teamData.id, teamName, teamDescription);
    updateConfirmation && setTeamData({...teamData, name: teamName, description: teamDescription});
    console.log("Update confirmation:", updateConfirmation)
  }

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [imageError, setImageError] = useState("");
  const fileOnChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      setImageError("");
      let newImageURL =await handleFileChange(e, teamData.id, null);
      newImageURL && setTeamImageURL(newImageURL);
    }
    catch (error: any) {
      console.error("Error updating team image:", error);
      setImageError(error);
    }
  }

  return (
    <>
      {idOfUserToBeRemoved !== "" && <RemoveUserModal idOfUserToBeRemoved={idOfUserToBeRemoved} teamId={teamData.id} userName={nameOfUserToBeRemoved} setIdOfUserToBeRemoved={setIdOfUserToBeRemoved} refreshAthleteList={refreshAthleteList} />}

      {idOfAthleteToBeEdited !== "" && <EditAthleteModal idOfAthleteToBeEdited={idOfAthleteToBeEdited} setIdOfAthleteToBeEdited={setIdOfAthleteToBeEdited} refreshAthleteList={refreshAthleteList} />}

      {newAthleteModalOpen && <NewAthleteModal teamId={teamData.id} setNewAthleteModalOpen={setNewAthleteModalOpen} refreshAthleteList={refreshAthleteList} />}

      {addExistingUserType && <AddExistingUserModal userType={addExistingUserType} teamId={teamData.id} athleteList={teamData.users || []} coachList={teamData.coaches || []} setAddExistingUserModalOpen={setAddExistingUserType} refreshAthleteList={refreshAthleteList} />}

      <div className="bg-white px-6 pt-12">
        <div className="container mx-auto px-4">
          <div className="flex gap-4 items-start w-full">
            <img src={teamImageURL || "/user_placeholder.png"} alt="athlete" className="w-[99px] h-[99px] rounded-full" />

            <div className="mt-auto">
              <p className="uppercase font-medium" style={{fontSize: "10px"}}>Team Overview</p>
              <h1 className="text-2xl md:text-4xl font-bold">{teamData.name}</h1>
              <p>{teamData.description}</p>
            </div>

            
          </div>
          

          <div className="flex items-center gap-4 mt-6">
            {tabElements(teamFilter)}
            {teamFilter && (
              <button
              key={teamFilter}
              className="underline underline-offset-2"
            >
              {teamFilter}
            </button>)}
          </div>
        </div>
      </div>

      <div className="container mx-auto px-4 md:px-6 py-6">

        {(tab === "Athletes" || tab === "Coaches") &&
        <div className="flex flex-wrap items-center gap-4">
          <div className="flex items-center gap-2 pl-3 border bg-white rounded-lg w-full lg:max-w-[400px] md:mr-auto">
            <SearchIcon />
            <input 
              value={searchQuery} 
              type="text" 
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder={`Search ${tab === "Athletes" ? "Athletes" : "Coaches"}...`} 
              className="py-2 pr-4 bg-transparent outline-none w-full" />
          </div>

          <div className="flex items-center gap-2">
            <p className="shrink-0">Sort by:</p>
            <select
              className="bg-transparent w-full py-2 pl-2 outline-none cursor-pointer"
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value)}
            >
              <option value="All">All</option>
              <option value="Name">Name</option>
            </select>
          </div>

          {tab === "Athletes"
            ? <>
                <button
                  onClick={() => setNewAthleteModalOpen(true)} 
                  className="px-3 py-2 card shrink-0 no-wrap"
                >
                  <AddIcon />&nbsp;Create Athlete
                </button>

                <button
                  onClick={() => setAddExistingUserType("Athlete")} 
                  className="px-3 py-2 card border shrink-0 no-wrap"
                >
                  <AddIcon />&nbsp;Add Athlete
                </button>
              </>
            : tab === "Coaches" && 
                <button
                  onClick={() => setAddExistingUserType("Coach")}
                  className="px-3 py-2 card border shrink-0 no-wrap"
                >
                  <AddIcon />&nbsp;Add Coach
                </button>
          }

          <div className="flex items-center gap-1 px-2 py-1 card border">
            <button
              className={`px-3 py-1 rounded-lg ${viewMode === "Cards" ? "bg-mfx-lightblue" : ""}`}
              onClick={() => setViewMode("Cards")}
            >
              <GridViewIcon />
            </button>
            <button
              className={`px-3 py-1 rounded-lg ${viewMode === "List" ? "bg-mfx-lightblue" : ""}`}
              onClick={() => setViewMode("List")}
            >
              <MenuIcon />
            </button>
          </div>
        </div>
        }

        <div className="card border mt-4 p-4">
          <table className="table-auto w-full">
            {tab === "Athletes" && <>
              <thead>
                <tr className="border-b">
                  <th className="text-left pl-2">Athlete</th>
                  <th className="text-left pl-2">Weight</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {teamAthletes.map((athlete) => <>
                  <tr key={athlete.id}>
                    <td>
                      <a href={`/activity?uid=${athlete.id}`} className="flex gap-2 items-center cursor-pointer hover:underline">
                        <img src={athlete.profileImageURL || "/user_placeholder.png"} alt="profile" className="w-10 h-10 rounded-full" />
                        <div>
                          <p className="font-medium text-sm">{athlete.displayName}</p>
                          <h3 className="font-semibold text-lg">{athlete.email}</h3>
                        </div>
                      </a>
                    </td>
                    <td>
                      <p>{athlete.mass} {athlete.massUnit}</p>
                    </td>
                    <td>
                      <div className="flex gap-2 justify-end">
                        <button 
                          onClick={() => setIdOfAthleteToBeEdited(athlete.id)}
                          className="bg-mfx-blue text-white rounded-lg px-6 py-2 font-bold"
                        >Edit</button>
                        <button 
                          className="border border-red-500 text-red-500 hover:bg-red-500 hover:text-white rounded-lg px-6 py-2 font-bold"
                          onClick={() => {
                            setIdOfUserToBeRemoved(athlete.id);
                            setNameOfUserToBeRemoved(athlete.displayName);
                          }}
                        >Remove</button>
                      </div>
                    </td>
                  </tr>
                </>)}
              </tbody>
            </>}
            
            {tab === "Coaches" && <>
              <thead>
                <tr className="border-b">
                  <th className="text-left pl-2">Name</th>
                  <th className="text-left pl-2">Email</th>
                </tr>
              </thead>
              <tbody>
              {teamCoaches.map((coach) => (
              <tr key={coach.id}>
                <td>
                  <div className="flex gap-2 items-center">
                    <img src={coach.profileImageURL || "/user_placeholder.png"} alt="profile" className="w-10 h-10 rounded-full" />
                    <div>
                      <p className="font-medium" style={{fontSize: "10px"}}>{coach.id}</p>
                      <p className="font-bold text-lg">{coach.displayName}</p>
                    </div>
                  </div>
                </td>
                <td>
                  <p>{coach.email}</p>
                </td>
              </tr>
              ))}
            </tbody>
            </>}
          </table>

          {tab === "Settings" && <>
          <table className="table-auto w-full">
            <tr className="tr-no-color">
              <td>
                <p className="text-lg font-medium">Team Image:</p>
              </td>
              <td>
                <input 
                  type="file"
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  accept=".jpg, .jpeg, .png"
                  onChange={fileOnChange}
                />
                <img 
                  src={teamImageURL || "/user_placeholder.png"} 
                  alt="team"
                  className="w-20 h-20 rounded-full cursor-pointer"
                  onClick={() => fileInputRef.current?.click()}
                />
                <p className="mt-2">Your Team's display image. You can update this by simply clicking on the image and selecting a new file.</p>
                {imageError && <p className="text-red-500 text-sm">{imageError}</p>}
              </td>
            </tr>

            <tr className="tr-no-color">
              <td>
                <p className="text-lg font-medium">Team Name:</p>
              </td>
              <td className="flex justify-start gap-2">
                <input 
                  type="text"
                  value={teamName}
                  onChange={(e) => setTeamName(e.target.value)}
                  className="py-2 px-4 bg-transparent outline-none border border-[#333333] rounded-lg w-full flex-grow"
                />
              </td>
            </tr>
            <tr className="tr-no-color">
              <td>
                <p className="text-lg font-medium">Description:</p>
              </td>
              <td className="flex justify-start gap-2">
                <div className="flex-grow">
                  <textarea
                    value={teamDescription}
                    onChange={(e) => setTeamDescription(e.target.value)}
                    className="py-2 px-4 bg-transparent outline-none border border-[#333333] rounded-lg w-full"
                  />
                </div>
              </td>
            </tr>
            </table>
            <div className="flex flex-col md:flex-row md:justify-between">
              <div>
                {(teamName !== teamData.name || teamDescription !== teamData.description) && 
                  <button
                    onClick={() => attemptTeamUpdate()}
                    className="bg-mfx-blue text-white rounded-lg px-6 py-2 font-bold mt-6"
                  >Save Changes</button>
                }
              </div>

              {pendingDelete
                ? <div className="flex justify-start gap-2">
                    <div className="flex-grow">
                      <p>Please enter the team name <strong>"{teamData.name}"</strong> to confirm:</p>
                      <input
                        type="text"
                        value={deleteConfirmation}
                        onChange={(e) => setDeleteConfirmation(e.target.value)}
                        className="py-2 px-4 bg-transparent outline-none border border-white rounded-lg w-full"
                      />
                    </div>
                    
                    <button
                      disabled={deleteConfirmation !== teamName}
                      onClick={() => attemptTeamDelete()}
                      className="border-2 border-red-500 text-red-500 rounded-lg px-6 py-2 font-bold mt-6 mr-2"
                    >Confirm</button>
                    <button
                      onClick={() => setPendingDelete(false)}
                      className="border-2 border-white rounded-lg px-6 py-2 font-bold mt-6 mr-2"
                    >Cancel</button>
                  </div>
                : <div className="flex justify-end">
                    <button
                      onClick={() => setPendingDelete(true)}
                      className="border-2 border-red-500 text-red-500 rounded-lg px-6 py-2 font-bold mt-6"
                    >Delete</button>
                  </div>
              }
            </div>
          </>}
        </div>
      </div>
    </>
  );
}

export default TeamPage;