import React, { useState, useEffect, useRef } from "react";
import { handleFileChange } from "../utils";
import { getAuth } from "firebase/auth";

import { fetchUserProfileById, updateDetailsForUserProfile } from "../firebase/services/userService";

type NewTeamModalProps = {
  idOfAthleteToBeEdited: string;
  setIdOfAthleteToBeEdited: (id: string) => void;
  refreshAthleteList: () => Promise<void>;
};

const EditAthleteModal: React.FC<NewTeamModalProps> = ({ idOfAthleteToBeEdited, setIdOfAthleteToBeEdited, refreshAthleteList }) => {
  const [displayName, setDisplayName] = useState("");
  const [email, setEmail] = useState("");
  const [mass, setMass] = useState(0);
  const [massUnit, setMassUnit] = useState("Kg");
  const [profileImageURL, setProfileImageURL] = useState("" as string | null);
  const [attemptingUpdate, setAttemptingUpdate] = useState(false);
  const [errorUpdatingAthlete, setErrorUpdatingAthlete] = useState(false);

  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) throw new Error('No authenticated user found');

  useEffect(() => {
    async function fetchAthleteDetails() {
      const athlete = await fetchUserProfileById(idOfAthleteToBeEdited);
      if (athlete) {
        setDisplayName(athlete.displayName);
        setEmail(athlete.email);
        setMass(athlete.mass);
        setMassUnit(athlete.massUnit);
        setProfileImageURL(athlete.profileImageURL);
      }
    }

    idOfAthleteToBeEdited && idOfAthleteToBeEdited !== "" && fetchAthleteDetails();
  }, [idOfAthleteToBeEdited]);

  const attemptUpdateAthleteDetails = async () => {
    setAttemptingUpdate(true);
    try {
      await updateDetailsForUserProfile(idOfAthleteToBeEdited, displayName, email, mass, massUnit);
      await refreshAthleteList();
      setIdOfAthleteToBeEdited("");
    } catch (error) {
      setErrorUpdatingAthlete(true);
      console.error("Failed to update athlete details", error);
    }
    setAttemptingUpdate(false);
  }

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [imageError, setImageError] = useState("");
  const fileOnChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      setImageError("");
      let newImageURL = await handleFileChange(e, null, idOfAthleteToBeEdited);
      if (newImageURL && profileImageURL!== newImageURL) {
        setProfileImageURL(newImageURL);
      }
    }
    catch (error: any) {
      console.error("Error updating team image:", error);
      setImageError(error);
    }
  }

  return (
    <div className="modal">
      <div className="card p-6 flex flex-col md:min-w-[500px]">
        <h1 className="text-2xl font-bold text-center">Edit Athlete Details</h1>

        <input 
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          accept=".jpg, .jpeg, .png"
          onChange={fileOnChange}
        />
        <img 
          src={profileImageURL || "/user_placeholder.png"} 
          alt="team"
          className="w-20 h-20 rounded-full cursor-pointer"
          onClick={() => fileInputRef.current?.click()}
        />
        <p className="mt-2">{displayName}'s profile picture. You can update this by simply clicking on the image and selecting a new file.</p>
        {imageError && <p className="text-red-500 text-sm">{imageError}</p>}

        <form onSubmit={(e) => {
          e.preventDefault();
          attemptUpdateAthleteDetails();
        }}>
          <p className="mt-6">Athlete Name:</p>
          <input
            type="text"
            required={true}
            placeholder="Athlete name"
            value={displayName}
            onChange={(e) => setDisplayName(e.target.value)}
            className="py-1 px-2 bg-transparent outline-none border border-white rounded-lg w-full"
          />

          <p className="mt-4">Email Address:</p>
          <textarea
            placeholder="Athlete Email"
            required={true}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="py-1 px-2 bg-transparent outline-none border border-white rounded-lg w-full"
          />

          <p className="mt-2">Mass:</p>
          <div className="flex gap-2 md:gap-4">
            <input
              type="number"
              required={true}
              placeholder="Athlete mass"
              value={mass}
              onChange={(e) => setMass(parseInt(e.target.value))}
              className="py-1 px-2 bg-transparent outline-none border border-white rounded-lg w-full"
            />

            <select
              value={massUnit}
              onChange={(e) => setMassUnit(e.target.value)}
              className="py-1 px-2 bg-transparent outline-none border border-white rounded-lg w-full"
            >
              <option value="Kg">Kg</option>
              <option value="Lbs">Lbs</option>
            </select>
          </div>

          {errorUpdatingAthlete && <p className="text-red-500 mt-2">Error updating Athlete details. Please try again.</p>}

          <button 
            disabled={attemptingUpdate}
            className="bg-white text-black rounded-lg px-6 py-2 font-bold mt-4 w-full">{attemptingUpdate ? <i className="fas fa-spinner fa-spin"></i> : "Update Details"}</button>
        </form>

        <button 
          disabled={attemptingUpdate}
          onClick={() => setIdOfAthleteToBeEdited("")} className="border-2 border-white rounded-lg px-6 py-2 font-bold mt-2">Cancel</button>
      </div>
    </div>
  );
};

export default EditAthleteModal;