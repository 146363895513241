import React, { useState, useEffect } from "react";
import { removeUserFromTeam } from "../firebase/services/teamService";
import { removeTeamIdFromUserProfile } from "../firebase/services/userService";

type NewTeamModalProps = {
  idOfUserToBeRemoved: string;
  teamId: string;
  userName: string;
  setIdOfUserToBeRemoved: (id: string) => void;
  refreshAthleteList: () => Promise<void>;
};

const RemoveUserModal: React.FC<NewTeamModalProps> = ({ idOfUserToBeRemoved, teamId, userName, setIdOfUserToBeRemoved, refreshAthleteList }) => {
  const [attemptingRemove, setAttemptingRemove] = useState(false);
  const [errorRemovingUser, setErrorRemovingUser] = useState(false);

  useEffect(() => {
    console.log("New team modal mounted");
  }, []);

  const attemptRemoveUser = async () => {
    setAttemptingRemove(true);
    try {
      const removedUser = await removeUserFromTeam(teamId, idOfUserToBeRemoved);
      if (removedUser) {
        await removeTeamIdFromUserProfile(idOfUserToBeRemoved, teamId);
        await refreshAthleteList();
        setIdOfUserToBeRemoved("");
      } else setErrorRemovingUser(true);
    } catch (error) {
      setErrorRemovingUser(true);
      console.error("Failed to create team", error);
    }
    setAttemptingRemove(false);
  }

  return (
    <div className="modal">
      <div className="card p-6 flex flex-col">
        <h1 className="text-2xl font-bold text-center">Remove User</h1>

        <form onSubmit={(e) => {
          e.preventDefault();
          attemptRemoveUser();
        }}>
          <p className="mt-6">Are you sure you want to remove <strong>{userName}</strong> from the team?</p>

          {errorRemovingUser && <p className="text-red-500 mt-2">Failed to remove user from team. Please try again.</p>}
          
          <button
            disabled={attemptingRemove}
            className="bg-red-500 text-white rounded-lg px-6 py-2 font-bold mt-4 w-full">{attemptingRemove ? <i className="fas fa-spinner fa-spin"></i> : "Remove"}</button>
        </form>

        <button
          disabled={attemptingRemove}
          onClick={() => setIdOfUserToBeRemoved("")} className="border-2 border-white rounded-lg px-6 py-2 font-bold mt-2">Cancel</button>
      </div>
    </div>
  );
};

export default RemoveUserModal;