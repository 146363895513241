import React, { useState, useEffect } from "react";
import { createNewUserProfile } from "../firebase/services/userService";
import { addUserToTeam } from "../firebase/services/teamService";
import { getAuth } from "firebase/auth";
import CsvUploadModal from "./csvAthleteCreation";

type NewTeamModalProps = {
  teamId: string;
  setNewAthleteModalOpen: (value: boolean) => void;
  refreshAthleteList: () => Promise<void>;
};

const NewAthleteModal: React.FC<NewTeamModalProps> = ({ teamId, setNewAthleteModalOpen, refreshAthleteList }) => {
  const [displayName, setDisplayName] = useState("");
  const [email, setEmail] = useState("");
  const [mass, setMass] = useState(0);
  const [massUnit, setMassUnit] = useState("Kg");
  const [errorAddingUser, setErrorAddingUser] = useState(false);
  const [attemptingAddUser, setAttemptingAddUser] = useState(false);
  const [csvUploadModalOpen, setCsvUploadModalOpen] = useState(false);

  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) throw new Error('No authenticated user found');

  const closeAndRefresh = async () => {
    setNewAthleteModalOpen(false);
    await refreshAthleteList();
  }

  if (csvUploadModalOpen) return <CsvUploadModal teamId={teamId} setModalOpen={setCsvUploadModalOpen} refreshAthleteList={closeAndRefresh} />;

  const attemptCreateUserAndAddToTeam = async () => {
    setAttemptingAddUser(true);
    try {
      const newUserId = await createNewUserProfile(displayName, email, mass, massUnit, teamId);
      if (newUserId) { 
        await addUserToTeam(teamId, [newUserId], "Athlete")
        await refreshAthleteList();
        setNewAthleteModalOpen(false);
      }
      else setErrorAddingUser(true);
    } catch (error) {
      setErrorAddingUser(true);
      console.error("Failed to create team", error);
    }
    setAttemptingAddUser(false);
  }

  return (
    <div className="modal">
      <div className="card p-6 flex flex-col md:min-w-[500px]">
        <h1 className="text-2xl font-bold text-center">Create New Athlete</h1>

        <form onSubmit={(e) => {
          e.preventDefault();
          attemptCreateUserAndAddToTeam();
        }}>
          <p className="mt-6">Athlete Name:</p>
          <input
            type="text"
            required={true}
            placeholder="Athlete name"
            value={displayName}
            onChange={(e) => setDisplayName(e.target.value)}
            className="py-1 px-2 bg-transparent outline-none border border-mfx-blue rounded-lg w-full"
          />

          <p className="mt-4">Email Address:</p>
          <textarea
            placeholder="Athlete Email"
            required={true}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="py-1 px-2 bg-transparent outline-none border border-mfx-blue rounded-lg w-full"
          />

          <p className="mt-2">Mass:</p>
          <div className="flex gap-2 md:gap-4">
            <input
              type="number"
              required={true}
              placeholder="Athlete mass"
              value={mass}
              onChange={(e) => setMass(parseInt(e.target.value))}
              className="py-1 px-2 bg-transparent outline-none border border-mfx-blue rounded-lg w-full"
            />

            <select
              value={massUnit}
              onChange={(e) => setMassUnit(e.target.value)}
              className="py-1 px-2 bg-transparent outline-none border border-mfx-blue rounded-lg w-full"
            >
              <option value="Kg">Kg</option>
              <option value="Lbs">Lbs</option>
            </select>
          </div>

          {errorAddingUser && <p className="text-red-500 mt-2">Failed to add user to team. Please try again.</p>}

          <button
            disabled={attemptingAddUser}
            className="primary-btn rounded-lg px-6 py-2 font-bold mt-4 w-full"
          >
            {attemptingAddUser ? <i className="fas fa-spinner fa-spin"></i> : "Add Athlete"}
          </button>
        </form>

        <button
          disabled={attemptingAddUser}
          onClick={() => setNewAthleteModalOpen(false)} className="outline-btn rounded-lg px-6 py-2 font-bold mt-2"
        >
          Cancel
        </button>

        <div className="flex justify-center items-center gap-2 mt-4">
          <div className="flex-grow h-0.5 bg-white"></div>
          <p>OR</p>
          <div className="flex-grow h-0.5 bg-white"></div>
        </div>

        <button
          disabled={attemptingAddUser}
          onClick={() => setCsvUploadModalOpen(true)}
          className="primary-btn rounded-lg px-6 py-2 font-bold mt-2 w-full"
        >
          {attemptingAddUser ? <i className="fas fa-spinner fa-spin"></i> : "Upload Athlete CSV"}
        </button>

      </div>
    </div>
  );
};

export default NewAthleteModal;