import React, { useState, useEffect } from "react";
import { fetchProfilesUserIsDelegateFor } from "../firebase/services/userService";
import { addUserToTeam } from "../firebase/services/teamService";
import { UserProfile } from "../models";

type NewTeamModalProps = {
  userType: "Coach" | "Athlete";
  teamId: string;
  athleteList: string[];
  coachList: string[];
  setAddExistingUserModalOpen: (value: "Coach" | "Athlete" | null) => void;
  refreshAthleteList: () => Promise<void>;
};

const AddExistingUserModal: React.FC<NewTeamModalProps> = ({ userType, teamId, athleteList, coachList, setAddExistingUserModalOpen, refreshAthleteList }) => {
  const [validUsers, setValidUsers] = useState<UserProfile[]>([]);

  useEffect(() => {
    async function fetchProfiles() {
      try {
        const profiles = await fetchProfilesUserIsDelegateFor();
        setValidUsers(userType === "Coach" 
          ? profiles.filter(profile => !coachList.includes(profile.id))
          : profiles.filter(profile => !athleteList.includes(profile.id))
        );
      } catch (error) {
        console.error("Failed to fetch profiles", error);
      }
    }

    fetchProfiles();
  }, []);

  return (
    <div className="modal">
      <div className="card p-6 flex flex-col">
        <h1 className="text-2xl font-bold text-center">Add {userType} to Team</h1>

        <div className="flex flex-col gap-4 mt-4 overflow-y-scroll pr-2" style={{maxHeight: "65vh"}}>
          {validUsers.map(user => (
            <div key={user.id} className="flex items-center justify-between border-2 border-gray-200 rounded-lg p-4">
              <div>
                <h2 className="font-bold">{user.displayName}</h2>
                <p>{user.email}</p>
              </div>
              <button onClick={async () => {
                await addUserToTeam(teamId, [user.id], userType);
                await refreshAthleteList();
                setAddExistingUserModalOpen(null);
              }} className="bg-mfx-blue text-white rounded-lg px-6 py-2 font-bold">Add</button>
            </div>
          ))}
        </div>
        <button onClick={() => setAddExistingUserModalOpen(null)} className="border-2 border-mfx-blue hover-mfx-blue rounded-lg px-6 py-2 font-bold mt-2">Cancel</button>
      </div>
    </div>
  );
};

export default AddExistingUserModal;