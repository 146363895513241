import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer, CartesianGrid } from 'recharts';
import { fetchExerciseSetsByWorkoutId } from '../firebase/services/workoutService';
import { calculateSetAveragesForWorkout, calculateRepsForSet, formatRepForLineChart, formatRepForVelocityDiagram, formatStatValue, processDataIntoVSD } from '../utils';
import { initialLineSettings, workoutProtocols, imageForWorkoutProtocol } from '../constants';

import VelocitySequenceDiagram from './velocitySequenceDiagram';

import SsidChartIcon from '@mui/icons-material/SsidChart';
import InsightsIcon from '@mui/icons-material/Insights';

interface Props {
  workoutData: any,
}

const WorkoutDetails: React.FC<Props> = ({ workoutData }) => {
  const [selectedSet, setSelectedSet] = useState(null as null | number);
  const [selectedRep, setSelectedRep] = useState(null as null | number);
  const [workoutSets, setWorkoutSets] = useState([] as any[]);
  const [fetchingData, setFetchingData] = useState(true);
  const [lineChartData, setLineChartData] = useState([] as any[]);
  const [vsdData, setVsdData] = useState([] as any[]);
  const [timeOfWorkout, setTimeOfWorkout] = useState("");
  const [chartView, setChartView] = useState("Line" as "Line" | "VSD");
  const [chartLines, setChartLines] = useState(initialLineSettings);
  const [invalidWorkout, setInvalidWorkout] = useState(true);

  const updateChartLines = (index: number) => {
    const newChartLines = chartLines.map((line, i) => {
      if (i === index) {
        return { ...line, display: !line.display };
      }
      return line;
    });
    setChartLines(newChartLines);
  }

  useEffect(() => {
    async function fetchWorkouts() {
      setFetchingData(true);
      let fetchedExerciseSets = await fetchExerciseSetsByWorkoutId(workoutData.refId);
      console.log(fetchedExerciseSets);
      if (!fetchedExerciseSets) return;

      let orderedSets = fetchedExerciseSets.sort((a: any, b: any) => a.setNumber - b.setNumber);
      for (const set of orderedSets) {
        if (set.Reps) {
          set.Reps = set.Reps.filter((rep: any) => rep.concentricMovement !== undefined);
          set.Reps.length > 0 && setInvalidWorkout(false);
        }
      }

      const options: Intl.DateTimeFormatOptions = {
        hour: '2-digit',  // or 'numeric'
        minute: '2-digit',
        hour12: true
      };
      const formattedTime = new Intl.DateTimeFormat('en-US', options).format(workoutData.createdAt);
      setTimeOfWorkout(formattedTime);
      setFetchingData(false);
      setWorkoutSets(orderedSets);
    }

    workoutData && fetchWorkouts();
    console.log(workoutData, workoutProtocols[workoutData.workoutProtocol]);
  }, [workoutData]);

  useEffect(() => {
    async function setChartData() {
      setFetchingData(true);
      setLineChartData([]);

      if (!selectedSet) {
        setSelectedRep(null);
        chartView === "Line"
          ? setLineChartData(calculateSetAveragesForWorkout(workoutSets))
          : setVsdData(processDataIntoVSD(workoutSets, "Sets"));
      } else if (!selectedRep) {

        chartView === "Line" 
          ? setLineChartData(calculateRepsForSet(workoutSets[selectedSet - 1]))
          : setVsdData(processDataIntoVSD(workoutSets[selectedSet - 1].Reps, "Reps"))
      } else {
        let currentRep = workoutSets[selectedSet - 1].Reps[selectedRep - 1];
        chartView === "Line"
          ? setLineChartData([formatRepForLineChart(currentRep)])
          : setVsdData([formatRepForVelocityDiagram(currentRep)]);
      }

      setFetchingData(false);
    }

    setChartData();
  }, [workoutSets, selectedSet, selectedRep, chartView])

  if (fetchingData || !workoutData) return (
    <div className="card p-6 w-full mb-4">
      <div className="flex items-center justify-center gap-6 py-6 flex-grow w-full">
        <i className="fas fa-spinner fa-spin text-6xl"></i>
        <h3 className="font-medium text-2xl">Fetching Workout Data</h3>
      </div>
    </div>
  );

  if (!fetchingData && invalidWorkout) return (
    <div className="card p-6 w-full mb-4">
      <div className="flex flex-col items-center justify-center gap-6 py-12 flex-grow w-full">
        <img src="/404.png" alt="404" className="w-36 h-36" />
        <h3 className="font-medium text-2xl">Missing Workout Data...</h3>
      </div>
    </div>
  );

  return (
    <div className="card p-6 w-full mb-4">
      <div className="flex items-center gap-4 py-4 px-2">
        <img className="w-12 md:w-14" src={`exercise_icons/${imageForWorkoutProtocol(workoutData.workoutProtocol)}.png`} alt={workoutData.workoutProtocol} />

        <div>
          {timeOfWorkout && <p className="text-xs">{timeOfWorkout}</p>}
          <h2 className="text-2xl font-bold">{workoutData.workoutProtocol} Workout</h2>
        </div>
      </div>


      <div className="flex flex-col md:flex-row items-center gap-4">


          <div className="border border-white rounded-lg p-2">
            <select
              className="bg-transparent outline-none cursor-pointer"
              onChange={(e) => e.target.value === "All" ? setSelectedSet(null) : setSelectedSet(parseInt(e.target.value))}
            >
              {workoutSets.length > 1 && <option value="All">All Sets</option>}
              {workoutSets.map((set: any, index: number) => (
                <option key={index} value={set.setNumber}>Set {index + 1}</option>
              ))}
            </select>
          </div>


        {selectedSet && (
          <select
            className="bg-transparent py-2 pl-2 border border-white rounded-lg p-2 outline-none cursor-pointer"
            onChange={(e) => e.target.value === "All" ? setSelectedRep(null) : setSelectedRep(parseInt(e.target.value))}
          >
            <option value="All">All Reps</option>
            {workoutSets[selectedSet - 1].Reps.map((rep: any, index: number) => (
              <option key={index} value={rep.repNumber}>Rep {index + 1}</option>
            ))}
          </select>
        )}

        <div className="ml-auto border border-white rounded-lg p-2 cursor-pointer" onClick={() => setChartView(chartView === "Line" ? "VSD" : "Line")}>
          {chartView === "Line" 
            ? <><InsightsIcon /><span className="ml-1">Velocity Sequence</span></> 
            : <><SsidChartIcon /><span className="ml-1">Line Chart</span></>
          }
        </div>
      </div>

      <div className="bg-white p-2 rounded-lg mt-4">
        {chartView === "Line" 
          ? <>
              <div className="flex items-center gap-6 my-4">
                {chartLines.map((line, index) => (
                  <div 
                    className="flex items-center gap-2 cursor-pointer" 
                    onClick={() => updateChartLines(index)}
                    key={index}>
                    <div className="h-[2px] w-4" style={{backgroundColor: line.display ? line.stroke : "#797979"}} />
                    <p className={`text-lg ${line.display ? "font-medium" : "text-[#FEFEFE]"}`}>{line.dataKey}</p>
                  </div>
                ))}
              </div>
              <div className="w-full mt-6">
                <ResponsiveContainer width="100%" height={400}>
                  <LineChart data={lineChartData} >
                    <XAxis dataKey="name" tick={{ fill: 'white' }} />
                    <CartesianGrid stroke="#797979" />
                    <YAxis yAxisId="left" tick={{ fill: 'white' }}  />
                    <YAxis yAxisId="right" tick={{ fill: 'white' }} orientation='right' unit="m/s" />
                    <Tooltip 
                      contentStyle={{ backgroundColor: '#444444', color: 'white' }} 
                      itemStyle={{ color: 'white' }} 
                      labelFormatter={(label) => `${!selectedSet ? "Set" : "Rep"} ${label}`}
                      formatter={(value: number, name: string) => {
                        // Precision formatting
                      let formattedValue = formatStatValue(name, value);
                        
                        return [formattedValue, name];
                      }}
                    />
                    {chartLines.map((line, index) => line.display && <Line key={index} type="monotone" dataKey={line.dataKey} stroke={line.stroke} strokeWidth={2} dot={false} yAxisId={line.dataKey === "Velocity" ? "right" : "left"} />)}
                  </LineChart>
                </ResponsiveContainer>
                <h3 className="text-center text-lg">{!selectedSet ? "Sets" : "Reps"}</h3>
              </div>
            </>
          : <div className="w-full mt-6">
              <VelocitySequenceDiagram chartData={vsdData} viewLevel={!selectedSet ? "Set" : "Rep"} />
            </div>
        }
      </div>
    </div>
  );
}

export default WorkoutDetails;