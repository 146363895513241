import React, { useState } from "react";
import { Link } from "react-router-dom";
import { signIn } from "../firebase/auth/authService";

interface FirebaseError extends Error {
  code: string;
}

const SignInPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("")

  const handleSignIn = async (event?: React.FormEvent) => {
    event?.preventDefault();
    try {
      const user = await signIn(email, password);
      setError("");
      console.log("Successfully signed in.", user);
    } catch (err) {
      console.log(err)

      const error = err as FirebaseError;

      let errorMessage;
      switch (error.code) {
        case 'auth/user-not-found':
          errorMessage = "No user found with this email. Please sign up.";
          break;
        case 'auth/wrong-password':
          errorMessage = "Incorrect password. Please try again.";
          break;
        case 'auth/too-many-requests':
          errorMessage = "Too many attempts. Please try again later.";
          break;
        default:
          errorMessage = "An unexpected error occurred. Please try again.";
      }

      setError(errorMessage);
    }
  }

  return (
    <div className="h-screen w-full grid md:grid-cols-2 items-center justify-center">
      <div className="h-screen w-full">
        <img src="/login_hero.webp" alt="background" className="object-cover h-full w-full" />
      </div>

      <div className="bg-white h-full p-4 text-center flex flex-col justify-center items-center">
        <h1 className="text-2xl font-bold">Sign In</h1>
        <form className="flex flex-col gap-4 mt-4" onSubmit={handleSignIn}>
          <input 
            type="text" 
            placeholder="Email" 
            className="primary_input" 
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />

          <input 
            type="password" 
            placeholder="Password" 
            className="primary_input"
            onChange={(e) => setPassword(e.target.value)}
            value={password} 
          />

          <button 
            type="submit"
            className="rounded-lg bg-mfx-blue text-white py-2"
          >
            Sign In
          </button>

          {error && <p className="text-red-500">{error}</p>}
        </form>
        <Link to="/forgot-password" className="text-xs mt-4">Forgot your password?</Link>
      </div>
    </div>
  );
}

export default SignInPage;