import React, {useState, useEffect, createContext } from "react";
import { fetchWorkoutsByUserId } from "./firebase/services/workoutService";
import { fetchUserTeams } from "./firebase/services/teamService";
import { getCurrentUserId } from "./utils";

const StateContext = createContext({
  isSideNavOpen: false,
  toggleSideNav: () => {},
  userIsAthlete: false,
  userIsCoach: false,
  hasDoneFetch: false
});

const StateProvider = ({ children }: { children: React.ReactNode }) => {
  const [isSideNavOpen, setIsSideNavOpen] = useState(false);
  const [userIsCoach, setUserIsCoach] = useState(false);
  const [userIsAthlete, setUserIsAthlete] = useState(false);
  const [hasDoneFetch, setHasDoneFetch] = useState(false);
  const toggleSideNav = () => setIsSideNavOpen(!isSideNavOpen);
  const currentUserId = getCurrentUserId();

  useEffect(() => {
    const setUserStatuses = async () => {
      if (!currentUserId) return;
      try {
        const personalWorkouts = await fetchWorkoutsByUserId(currentUserId, 1);
        console.log("Personal workouts", personalWorkouts);
        personalWorkouts.length > 0 && setUserIsAthlete(true);

        const teams = await fetchUserTeams(null);
        teams && teams.length > 0 && setUserIsCoach(true);
        setHasDoneFetch(true);
      } catch (error) {
        console.error("Failed to fetch workouts for user: ", error);
      }
    }

    currentUserId && !hasDoneFetch && setUserStatuses();
  }, [currentUserId, hasDoneFetch]);

  return (
    <StateContext.Provider value={{isSideNavOpen, toggleSideNav, userIsAthlete, userIsCoach, hasDoneFetch }}>
      {children}
    </StateContext.Provider>
  );
}

export {StateContext, StateProvider};