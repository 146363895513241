import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';

export const signIn = async (email: string, password: string) => {
  const auth = getAuth();
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    // Sign-in successful.
    //console.log('User signed in:', userCredential.user);
    return userCredential.user;
  } catch (error) {
    //console.error('Error signing in:', error);
    throw error;
  }
};