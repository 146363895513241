import React, { useEffect, useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import { fetchUserProfileById } from "../firebase/services/userService";
import { StateContext } from "../context";
import { getAuth } from "firebase/auth";
import { UserProfile } from "../models";

import AthleteDetails from "../components/athleteDetails";

const AthleteActivity = () => {
  const { userIsAthlete, hasDoneFetch } = useContext(StateContext);

  const user = getAuth().currentUser;
  console.log(user);
  if (!user) throw new Error('No authenticated user found');

  const [failedToFetch, setFailedToFetch] = useState(false);
  const [athleteData, setAthleteData] = useState<UserProfile | null>(null);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const currentAthleteId = searchParams.get("uid");

  if (hasDoneFetch && !userIsAthlete && currentAthleteId === user.uid)  {
    console.log("Would be kicked out of page A");
    //window.location.href = "/";
  }

  useEffect(() => {
    const fetchUserData = async (currentAthleteId: string) => {
      try {
        const userData = await fetchUserProfileById(currentAthleteId);
        console.log(userData)
        userData && setAthleteData(userData);
      } catch (error) {
        console.error('Failed to fetch user data', error, user.uid);
        setFailedToFetch(true);
        // Handle error appropriately
      }
    };

    if (currentAthleteId) {
      fetchUserData(currentAthleteId)
    } else {
      console.log("Kicking user out of page");
      //window.location.href = "/404";
    }
  }, [location.search]);
  
  
  if (athleteData) return <AthleteDetails athleteInfo={athleteData} />
}

export default AthleteActivity;