import React from 'react';
import { ScatterChart, Scatter, XAxis, YAxis, Tooltip, Legend, ReferenceLine, ResponsiveContainer } from 'recharts';

type VelocitySequenceDiagramProps = {
  chartData: any[];
  viewLevel: string;
};

const VelocitySequenceDiagram: React.FC<VelocitySequenceDiagramProps> = ({ chartData, viewLevel }) => {
  console.log(chartData);

  const CustomTooltip: React.FC<{active?: boolean; payload?: any[]}> = ({ active, payload }) => {
    return (active && payload && payload.length)
      ?
        <div className="custom-tooltip text-center" style={{ backgroundColor: '#333333', color: 'white', padding: '10px', borderRadius: '10px' }}>
          <p className="label">{payload[0].payload.label}</p>
          <p className="intro">{`Time: ${Number(payload[0].value.toPrecision(4))}ms`}</p>
          <p className="desc">{`Velocity: ${Number(payload[1].value.toPrecision(3))}m/s`}</p>
        </div>
      : null
  };

  const VerticalCursor: React.FC<{x?: number; height?: number;}> = ({ x, height }) => {
    return (x === undefined || height === undefined) ? null : <line x1={x} x2={x} y1={0} y2={height} stroke="white" />;
  };

  return (
    <ResponsiveContainer width="100%" height={400}>
      <ScatterChart width={400} height={400}>
        <XAxis type="number" dataKey="x" name="Time" unit="ms" />
        <YAxis type="number" dataKey="y" name="Velocity" unit="m/s" />
        <Tooltip
          contentStyle={{ backgroundColor: '#444444', color: 'white' }}
          itemStyle={{ color: 'white' }}
          content={<CustomTooltip />}
          cursor={<VerticalCursor />}
        />
        <Legend />
        {chartData.map((data, index) => (
          <Scatter 
            key={index} 
            name={data[0].label} 
            data={data} 
            fill="#1c1f2c"
            r={20} lineJointType='monotoneX' line 
          />
        ))}
        <ReferenceLine x={-200} stroke="#1c1f2c60" strokeWidth={2} strokeDasharray="3 3" />
        <ReferenceLine x={-100} stroke="#1c1f2c60" strokeWidth={1} strokeDasharray="3 3" />
        <ReferenceLine x={100} stroke="#1c1f2c60" strokeWidth={1} strokeDasharray="3 3" />
        <ReferenceLine x={200} stroke="#1c1f2c60" strokeWidth={2} strokeDasharray="3 3" />
      </ScatterChart>
    </ResponsiveContainer>
  )
}

export default VelocitySequenceDiagram;