import { useState, useEffect } from 'react';
import { getCurrentUserId } from '../utils';
import { fetchUserTeams } from '../firebase/services/teamService';
import { Team } from '../models';

function useUserTeams() {
  const [userTeams, setUserTeams] = useState<Team[]>([]);
  const loggedInUserId = getCurrentUserId();

  useEffect(() => {
    const loadUserTeams = async () => {
      if (!loggedInUserId) {
        setUserTeams([]);
        return;
      }

      const teams = await fetchUserTeams(null);
      teams && setUserTeams(teams);
    };

    loadUserTeams();
  }, [loggedInUserId]);

  return userTeams;
}

export default useUserTeams;