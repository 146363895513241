import { collection, query, where, doc, getDoc, getDocs, deleteDoc, addDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase_config';
import { Team } from '../../models';
import { getCurrentUserId } from '../../utils';

export const createTeam = async (teamName: string, teamDescription: string, userId: string): Promise<string> => {
  const teamsCollection = collection(db, 'Teams');

  const docRef = await addDoc(teamsCollection, {
    name: teamName,
    description: teamDescription,
    users: [],
    coaches: [userId],
    createdBy: userId,
    createdAt: new Date()
  });

  await updateDoc(doc(db, 'Teams', docRef.id), { refId: docRef.id });
  return docRef.id;
};

export const fetchUserTeams = async (teamId: string | null): Promise<Team[] | null> => {
  try {
    const userId = getCurrentUserId();
    const teamsCollection = collection(db, 'Teams');

    if (teamId) {
      console.log('Fetching team with ID:', teamId, 'for user:', userId);
      const q = query(teamsCollection, where("createdBy", "==", userId), where("refId", "==", teamId));
      let querySnapshot = await getDocs(q);
      console.log('Query snapshot:', querySnapshot.docs);

      if (querySnapshot.empty) { 
        let backupQuery = query(teamsCollection, where("coaches", "array-contains", userId), where("refId", "==", teamId));
        querySnapshot = await getDocs(backupQuery);
      }

      if (querySnapshot.empty) {
        console.error('No team found with ID:', teamId);
        return null;
      }

      const teams: Team[] = querySnapshot.docs.map(doc => ({
        ...(doc.data() as Team),
        id: doc.id,
      }));

      return teams;
    } else {
      console.log('Fetching all teams for user:', userId);

      const createdByQuery = query(teamsCollection, where("createdBy", "==", userId));
      const createdBySnapshot = await getDocs(createdByQuery);

      const coachesQuery = query(teamsCollection, where("coaches", "array-contains", userId));
      const coachesSnapshot = await getDocs(coachesQuery);

      const teamsMap = new Map<string, Team>();
      createdBySnapshot.forEach((doc) => {
        teamsMap.set(doc.id, { ...(doc.data() as Team), id: doc.id });
      });

      coachesSnapshot.forEach((doc) => {
        if (!teamsMap.has(doc.id)) {
          teamsMap.set(doc.id, { ...(doc.data() as Team), id: doc.id });
        }
      });

      const teams = Array.from(teamsMap.values());
      return teams;
    }
  } catch (error) {
    console.error('Error fetching teams:', error);
    return null;
  }
};

export const removeUserFromTeam = async (teamId: string, idOfUserToBeRemoved: string): Promise<boolean> => {
  try {
    const teamDocRef = doc(db, 'Teams', teamId);
    const teamDoc = await getDoc(teamDocRef);

    if (!teamDoc.exists()) {
      console.error('Team not found');
      throw new Error('Team not found');
    }

    const teamData = teamDoc.data();
    console.log(teamData);
    if (!teamData) {
      console.error('No data available for the team');
      throw new Error('No data available for the team');
    }

    if (teamData.createdBy === idOfUserToBeRemoved) {
      console.error('Cannot remove team creator from team');
      throw new Error('Cannot remove team creator from team');
    }

    if (!teamData.users || !Array.isArray(teamData.users)) {
      console.error('Invalid or undefined members array');
      throw new Error('Invalid or undefined members array');
    }

    const usersArrayWithoutUser = teamData.users.filter((user: string) => user !== idOfUserToBeRemoved);

    // Ensure we don't try to update if the array hasn't changed
    if (usersArrayWithoutUser.length === teamData.users.length) {
      console.error('User not found in team members', idOfUserToBeRemoved, teamData.users);
      throw new Error('User not found in team members');
    }

    await updateDoc(teamDocRef, { users: usersArrayWithoutUser });
    return true;
  } catch (error) {
    console.error('Failed to remove user from team', error);
    throw error;
  }
};

export const updateTeamNameAndDescription = async (teamId: string, teamName: string, teamDescription: string): Promise<boolean> => {
  try {
    const teamDocRef = doc(db, 'Teams', teamId);
    await updateDoc(teamDocRef, { name: teamName, description: teamDescription });
    return true;
  }
  catch (error) {
    console.error('Failed to update team name and description', error);
    throw error;
  }
}

export const updateTeamImageURL = async (teamId: string, imageUrl: string): Promise<boolean> => {
  try {
    const teamDocRef = doc(db, 'Teams', teamId);
    await updateDoc(teamDocRef, { TeamImageURL: imageUrl });
    return true;
  }
  catch (error) {
    console.error('Failed to update team profile with image URL', error);
    throw new Error('Failed to update team profile with image URL');
  }
};

export const addUserToTeam = async (teamId: string, userIds: string[], userType: "Athlete" | "Coach"): Promise<boolean> => {
  try {
    console.log('Adding users to team:', teamId, userIds, userType)

    const teamDocRef = doc(db, 'Teams', teamId);
    const teamDoc = await getDoc(teamDocRef);

    if (!teamDoc.exists()) {
      console.error('Team not found');
      throw new Error('Team not found');
    }

    const teamData = teamDoc.data();
    if (!teamData) {
      console.error('No data available for the team');
      throw new Error('No data available for the team');
    }

    let objectKey = userType === "Coach" ? "coaches" : "users";

    if (!teamData[objectKey] || !Array.isArray(teamData[objectKey])) {
      console.error(`Invalid or undefined ${objectKey} array`);
      throw new Error(`Invalid or undefined ${objectKey} array`);
    }

    // If just one userId is passed 
    const previousArray = teamData[objectKey];
    for (const userId of userIds) {
      // If user isn't already in the team, add them
      if (!previousArray.includes(userId)) previousArray.push(userId);
    }

    await updateDoc(teamDocRef, { [objectKey]: previousArray });
    return true;
  } catch (error) {
    console.error('Failed to add user to team', error);
    throw error;
  }
}

export const deleteTeam = async (teamId: string): Promise<boolean> => {
  try {
    const teamDocRef = doc(db, 'Teams', teamId);
    await deleteDoc(teamDocRef);
    return true;
  }
  catch (error) {
    console.error('Failed to delete team', error);
    throw error;
  }
};