import React, {useEffect, useState} from "react";
import Calendar from "react-calendar";
import 'react-calendar/dist/Calendar.css';

import { Workout } from "../models";

interface Props {
  workouts: Workout[];
  setSelectedDate: (date: Date) => void;
  selectedDate: Date;
  setCalendarOpen: (value: boolean) => void;
}

const CalendarComponent: React.FC<Props> = ({ workouts, setSelectedDate, selectedDate, setCalendarOpen }) => {
  const [clicksOutside, setClicksOutside] = useState(0);

  const dateCellContent = ({ date, view }: { date: Date; view: string }): JSX.Element => {
    // Check if there's an exercise on this date
    const workoutOnDay = workouts.some((workout) => {
      //console.log(date.getFullYear(), workout.createdAt.getFullYear());
      return (
        date.getFullYear() === workout.createdAt.getFullYear() &&
        date.getMonth() === workout.createdAt.getMonth() &&
        date.getDate() === workout.createdAt.getDate()
      );
    });

    // Return a marker if an exercise exists
    return (
      <div>
        {view === 'month' && workoutOnDay && <span className="calendar-marker"></span>}
      </div>
    );
  };

  const handleDateSelect = (date: Date) => {
    console.log('Selected date:', date);
    setSelectedDate(date);
    setCalendarOpen(false);
  };

  // Listen for clicks, if not on calendar, close the calendar
  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      const calendar = document.querySelector('.react-calendar');
      if (calendar && !calendar.contains(e.target as Node)) {
        console.log('Clicked outside calendar');
        setClicksOutside(prevClicks => prevClicks + 1);
      }
    }

    document.addEventListener('click', handleClick);
    
    // Cleanup function
    return () => document.removeEventListener('click', handleClick);
  }, []);

  useEffect(() => {
    if (clicksOutside > 1) {
      setCalendarOpen(false);
      setClicksOutside(0);
    }
  }, [clicksOutside, setCalendarOpen]);

  return (
    <div className="absolute top-8 left-0 card p-4 z-10" >
      <Calendar
        tileContent={dateCellContent} 
        onClickDay={handleDateSelect}
        defaultValue={selectedDate}
      />
    </div>
  );
}

export default CalendarComponent;