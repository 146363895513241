import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, signOut } from 'firebase/auth';

const SignOutPage: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const auth = getAuth();
    signOut(auth).then(() => {
      navigate('/signin', { replace: true });
    }).catch((error) => {
      console.error('Logout Error:', error);
    });
  }, [navigate]);

  return <div>Logging out...</div>;
}

export default SignOutPage;