import React, {useEffect, useState} from "react";
import Calendar from "react-calendar";
import 'react-calendar/dist/Calendar.css';

interface Props {
  setSelectedDate: (date: Date) => void;
  selectedDate: Date | null;
  closeCalendar: () => void;
}

const ComparisonCalendar: React.FC<Props> = ({ setSelectedDate, selectedDate, closeCalendar }) => {
  const [clicksOutside, setClicksOutside] = useState(0);

  const handleDateSelect = (date: Date) => {
    console.log('Selected date:', date);
    setSelectedDate(date);
    closeCalendar();
  };

  // Listen for clicks, if not on calendar, close the calendar
  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      const calendar = document.querySelector('.react-calendar');
      if (calendar && !calendar.contains(e.target as Node)) {
        console.log('Clicked outside calendar');
        setClicksOutside(prevClicks => prevClicks + 1);
      }
    }

    document.addEventListener('click', handleClick);
    
    // Cleanup function
    return () => document.removeEventListener('click', handleClick);
  }, []);

  useEffect(() => {
    if (clicksOutside > 1) {
      closeCalendar();
      setClicksOutside(0);
    }
  }, [clicksOutside, closeCalendar]);

  return (
    <div className="absolute top-8 left-0 card p-4 z-10" >
      <Calendar
        onClickDay={handleDateSelect}
        defaultValue={selectedDate}
      />
    </div>
  );
}

export default ComparisonCalendar;