import React from 'react';
import { RouterProvider, createBrowserRouter, Navigate } from "react-router-dom";
import useAuthState from './hooks/useAuthState';
import { StateProvider } from './context';

import Nav from './components/nav';

import AthleteActivity from './pages/activity'
import Dashboard from './pages/dashboard';
import TeamPage from './pages/team';
import SignInPage from './pages/signIn';
import ErrorPage from './pages/error';
import SignOutPage from './pages/signOut';

function App() {
  const { user, loading } = useAuthState();

  if (loading) {
    return <div className="h-screen w-screen flex flex-col items-center justify-center gap-6">
      <i className="fas fa-spinner fa-spin text-3xl md:text-7xl"></i>
      <h3 className="font-bold text-2xl md:text-4xl text-center">Loading...</h3>
    </div>;
  }

  // Create a function that takes child and outputs JSX div with child inside
  const SignedInLayout = ({ children }) => {
    return (
      <div className="flex flex-col md:flex-row h-screen w-screen">
        <Nav />
        <div className="flex-grow overflow-y-scroll h-screen">
          {children}
        </div>
      </div>
    )
  }

  const wrapChildOrRedirect = (child) => {
    return user ? <SignedInLayout>{child}</SignedInLayout> : <Navigate to="/signin" replace />;
  }

  const router = createBrowserRouter([
    { path: "/", element: wrapChildOrRedirect(<Dashboard />) },
    { path: "/activity", element: wrapChildOrRedirect(<AthleteActivity />) },
    { path: "/team", element: wrapChildOrRedirect(<TeamPage />) },
    { path: "/signin", element: user ? <Navigate to="/" replace /> : <SignInPage /> },
    { path: "/signout", element: <SignOutPage /> },
    { path: "/404", element: <ErrorPage /> },
    { path: "*", element: <Navigate to="/404" replace /> }
  ]);

  return (
    <StateProvider>
      <RouterProvider router={router} />
    </StateProvider>
  );
}

export default App;