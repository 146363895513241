import React, { useState, useEffect } from 'react';
import { fetchWorkoutsByUserId } from '../firebase/services/workoutService';
import { Workout, UserProfile } from '../models';
import { format } from 'date-fns/format';

import CalendarComponent from './calendar';
import WorkoutDetails from './workoutDetails';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PerformanceComparison from './performanceComparison';

type AthleteDetailProps = {
  athleteInfo: UserProfile;
};

const AthleteDetails: React.FC<AthleteDetailProps> = ({ athleteInfo }) => {
  const [tab, setTab] = useState("Latest Updates");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [allWorkouts, setAllWorkouts] = useState([] as Workout[]);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [workoutsForSelectedDate, setWorkoutsForSelectedDate] = useState([] as Workout[]);

  useEffect(() => {
    const fetchWorkouts = async () => {
      try {
        const fetchedWorkouts = await fetchWorkoutsByUserId(athleteInfo.id, null);
        fetchedWorkouts.sort((a, b) => (a.createdAt < b.createdAt) ? 1 : -1);
        console.log(fetchedWorkouts)
        if (fetchedWorkouts[0]) {
          setAllWorkouts(fetchedWorkouts);
          let dateOfMostRecentWorkout = fetchedWorkouts[0].createdAt;
          setSelectedDate(dateOfMostRecentWorkout);
        }
      } catch (error) {
        console.error('Failed to fetch workouts', error);
      }
    };

    fetchWorkouts();
    console.log(athleteInfo)
  }, [athleteInfo]);

  useEffect(() => {
    tab === "Latest Updates" && setWorkoutsForSelectedDate(allWorkouts.filter(workout => workout.createdAt.toDateString() === selectedDate.toDateString()))
  }, [selectedDate, allWorkouts, tab]);

  const tabOptions = ["Latest Updates", "Performance Comparison"];
  const tabElements = tabOptions.map(tabName => (
    <button
      key={tabName}
      className={`${(tab === tabName) ? "border-[#333333] font-bold" : "border-white"} text-lg px-2 border-b-4`}
      onClick={() => setTab(tabName)}
    >
      {tabName}
    </button>
  ));

  return (
    <>
      <div className="bg-white px-6 pt-8">
        <div className="container mx-auto px-4">
          <div className="flex gap-4 items-start w-full">
            <div>
              <img 
                src={athleteInfo.profileImageURL || "/user_placeholder.png"} 
                alt="team"
                className="w-20 h-20 rounded-full cursor-pointer"
              />
            </div>

            <div className="mt-auto">
              <h1 className="text-2xl font-bold">{athleteInfo.displayName}</h1>
              <p>{athleteInfo.team}</p>
              <p className="font-thin">Weight:&nbsp;{athleteInfo.mass}&nbsp;{athleteInfo.massUnit}</p>
            </div>
          </div>

          <div className="flex items-center gap-4 mt-6">
            {tabElements}
          </div>
        </div>
      </div>

      <div className="container mx-auto pt-4 pb-12 px-4">
        {tab === "Latest Updates" && (
          <>
            <div className="relative my-4 flex items-start">
              <div className="card p-2 pl-3 flex items-center gap-2 text-lg cursor-pointer" onClick={() => setCalendarOpen(!calendarOpen)}>
                <h3><i className="fa-regular fa-calendar text-xl"></i>&nbsp;&nbsp;{format(selectedDate, 'EEEE MM/dd/yyyy')}</h3><KeyboardArrowDownIcon />
              </div>

              {calendarOpen && <CalendarComponent workouts={allWorkouts} setSelectedDate={setSelectedDate} selectedDate={selectedDate} setCalendarOpen={setCalendarOpen} />}
            </div>

            {workoutsForSelectedDate && (
              workoutsForSelectedDate.length === 0
                ? <div className="card text-center py-12">
                    <h3 className="font-medium text-2xl">No workouts for this date...</h3>
                  </div>
                : workoutsForSelectedDate && workoutsForSelectedDate.map((workout: any, index: number) => 
                    <WorkoutDetails workoutData={workout} /> 
                  )
            )}
          </>
        )}

        {tab === "Performance Comparison" && (
          <PerformanceComparison allWorkouts={allWorkouts} initialWorkoutProtocol={allWorkouts[0].workoutProtocol || "Squat"} />
        )}

      </div>
    </>
  );
}

export default AthleteDetails;