import React, { useState, useEffect, useRef } from "react";
import { getAuth } from "firebase/auth";
import Papa from 'papaparse';

import { createNewUserProfile } from "../firebase/services/userService";
import { addUserToTeam } from "../firebase/services/teamService";

type NewTeamModalProps = {
  teamId: string;
  setModalOpen: (value: boolean) => void;
  refreshAthleteList: () => Promise<void>;
};

const CsvUploadModal: React.FC<NewTeamModalProps> = ({ teamId, setModalOpen, refreshAthleteList }) => {
  const [data, setData] = useState([] as any[]);
  const [errors, setErrors] = useState([] as string[]);
  const [isUploading, setIsUploading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) throw new Error('No authenticated user found');

  const handleFileUpload = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          const parsedData = results.data;
          const validationErrors = validateData(parsedData);
          if (validationErrors.length > 0) {
            setErrors(validationErrors);
          } else {
            setData(parsedData);
            setErrors([]);
          }
        },
      });
    }
  };

  const validateData = (data: any) => {
    const errors = [] as string[];
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    data.forEach((row: any, index: number) => {
      if (!row.Name || !row.email || !row.weight || !row.weightUnit) {
        errors.push(`Row ${index + 1}: Missing fields`);
      } else if (!emailRegex.test(row.email)) {
        errors.push(`Row ${index + 1}: Invalid email format`);
      } else if (isNaN(row.weight) || row.weight <= 0) {
        errors.push(`Row ${index + 1}: Invalid weight`);
      } else if (row.weightUnit.toLocaleLowerCase !== 'kg' && row.weightUnit.toLocaleLowerCase !== 'lbs') {
        errors.push(`Row ${index + 1}: Invalid weight unit`);
      }
    });

    return errors;
  };

  const attemptCreateUsersAndAddToTeam = async () => {
    setIsUploading(true);
    const newUsers = [] as string[];
    for (const row of data) {
      try {
        const { Name, email, weight, weightUnit } = row;
        const newUserId = await createNewUserProfile(Name, email, weight, weightUnit, teamId);
        if (newUserId) {
          newUsers.push(newUserId);
        }
      } catch (error) {
        console.error("Failed to create user", error);
      }
    }

    // Create array of unique user IDs
    const uniqueUsers = [...new Set(newUsers)];
    console.log(uniqueUsers);
    
    await addUserToTeam(teamId, uniqueUsers, "Athlete");
    await refreshAthleteList();
    setIsUploading(false);
    setModalOpen(false);
  }

  return (
    <div className="modal">
      <div className="card p-6 flex flex-col md:min-w-[500px]">
        <h1 className="text-2xl font-bold text-center">Upload Athlete CSV</h1>

        {data.length === 0 
          ? <>
              <p className="mt-6">To bulk create Athletes you can upload a csv file. This file must contain the following columns <strong>in this specific order:</strong></p>

              <ul className="list-disc ml-6 mt-4">
                <li>Name - The athlete's first and last name as one string.</li>
                <li>Email - The athlete's email address.</li>
                <li>Weight - The athlete's weight.</li>
                <li>Unit - The athlete's weight unit, must be either "Kg" or "Lbs".</li>
              </ul>
              
              <input type="file" accept=".csv" onChange={handleFileUpload} ref={fileInputRef} className="hidden" />
              <button
                className="bg-white text-black rounded-lg px-6 py-2 font-bold mt-6 w-full"
                onClick={() => fileInputRef.current?.click()}
              >Select File</button>

              <button
                className="border border-white text-white rounded-lg px-6 py-2 font-bold mt-4 w-full"
                onClick={() => setModalOpen(false)}
              >Cancel</button>

              {errors.length > 0 && (
                <div>
                  <h3>Validation Errors:</h3>
                  <ul>
                    {errors.map((error, index) => (
                      <li key={index}>{error}</li>
                    ))}
                  </ul>
                </div>
              )}
            </>

          : <div>
              <p className="mt-6">We identified {data.length} athletes in your file. Please double check the data below before uploading.</p>

              <div className="mt-4 overflow-y-scroll" style={{maxHeight: "45vh"}}>
                <table className="table-auto w-full">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Weight</th>
                      <th>Unit</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((row, index) => (
                      <tr key={index}>
                        <td>{row.Name}</td>
                        <td className="text-center">{row.email}</td>
                        <td className="text-center">{row.weight}</td>
                        <td className="text-center">{row.weightUnit}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <button
                disabled={isUploading}
                className="bg-white text-black rounded-lg px-6 py-2 font-bold mt-4 w-full"
                onClick={() => attemptCreateUsersAndAddToTeam()}
              >{isUploading ? <><i className="fas fa-spinner fa-spin"></i>&nbsp;Uploading...</> : "Upload"}</button>

              <button
                disabled={isUploading}
                className="border border-white text-white rounded-lg px-6 py-2 font-bold mt-4 w-full"
                onClick={() => setData([])}
              >Cancel</button>
            </div>
        }
      </div>
    </div>
  );
};

export default CsvUploadModal;