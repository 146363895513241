import React from "react";
import { Link } from "react-router-dom";

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

const ErrorPage = () => {
  return (
    <div className="h-screen w-screen flex items-center justify-center">
      <div className="flex flex-col md:flex-row justify-center gap-8">
        <img src="/404.png" alt="404" className="w-52 h-52" />
        <div className="flex flex-col gap-4 items-start max-w-[450px]">
          <h1 className="font-extrabold text-4xl md:text-6xl">404</h1>
          <h3 className="font-bold text-xl md:text-2xl">Oops! Looks like someone has dropped the barbell...</h3>
          <p>The page you are looking for might have been removed or temporarily unavailable.</p>
          
          <Link to="/">
            <button className="bg-white text-black py-1 px-2 rounded-lg flex items-center text-sm">
              <KeyboardArrowLeftIcon />&nbsp;Go Back
            </button>
          </Link>

        </div>
      </div>

    </div>
  );
}

export default ErrorPage;
