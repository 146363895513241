import React from "react";

type AthleteCardProps = {
  id: string;
  name: string;
  description: string;
  numberOfMembers: number;
  imageURL: string;
  renderAs: string;
};

const TeamCard: React.FC<AthleteCardProps> = ({
  id,
  name,
  description,
  numberOfMembers,
  imageURL,
  renderAs
}) => {

  if (renderAs === "Card") {
    return (
      <a href={`/team?id=${id}`} key={id} className="cursor-pointer card p-2 md:p-4">
        <div className="flex flex-col items-center justify-end gap-2">
          <img src={imageURL} alt="athlete" className="w-[99px] h-[99px] rounded-full" />
          <h2 className="font-bold">{name}</h2>
          <h3 className="font-thin">{description}</h3>
          <p>Members: {numberOfMembers}</p>
        </div>
      </a>
    )
  }

  return (
    <tr onClick={() => {window.location.href = `/team?id=${id}`}} className="table-link-row">
      <td>
        <div className="flex items-center gap-4">
          <img src={imageURL} alt="athlete" className="w-10 h-10 rounded-full" />
          <h3 className="font-bold">{name}</h3>
        </div>
      </td>
      <td>
        <p>{description}</p>
      </td>
      <td>
        <p>{numberOfMembers}</p>
      </td>
    </tr>
  )
};

export default TeamCard;