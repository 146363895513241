import { doc, getDoc, collection, updateDoc, query, where, getDocs, Timestamp, setDoc } from 'firebase/firestore';
import { db } from '../firebase_config';
import { UserProfile } from '../../models';
import { getAuth } from 'firebase/auth';
import { getCurrentUserId, getCurrentUserEmail } from '../../utils';

export const fetchUserProfileById = async (userId: string): Promise<UserProfile | null> => {
  try {
    //console.log("Fetching user profile by ID", userId);

    const adminUserId = getCurrentUserId();

    // Fetch user profile by Id where createdBy is the current user
    const usersCollection = collection(db, 'UserProfiles');
    const q = query(usersCollection, where("userId", "==", userId), where("createdBy", "==", adminUserId));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const userDoc = querySnapshot.docs[0];
      //console.log("User profile found:", userDoc.data());
      return { ...userDoc.data(), id: userDoc.id } as UserProfile;
    } else {
      let fallbackQuery = query(usersCollection, where("userId", "==", userId));
      let fallbackQuerySnapshot = await getDocs(fallbackQuery);

      if (!fallbackQuerySnapshot.empty) {
        const userDoc = fallbackQuerySnapshot.docs[0];
        //console.log("User profile found:", userDoc.data());
        return { ...userDoc.data(), id: userDoc.id } as UserProfile;
      } else {
        console.log("No user profile found for ID:", userId);
        return null;
      }
    }
  } catch (error) {
    console.error('Failed to fetch user profile by ID', userId, error);
    throw new Error('Failed to fetch user profile');
  }
};

export const fetchMultipleUserProfilesByIds = async (userIds: string[]): Promise<UserProfile[]> => {
  function chunkArray(array: string[]) {
    const results = [];
    for (let i = 0; i < array.length; i += 10) {
      results.push(array.slice(i, i + 10));
    }
    return results;
  }

  try {
    const adminUserId = getCurrentUserId();
    const userChunks = chunkArray(userIds);

    const usersCollection = collection(db, 'UserProfiles');
    const userProfiles: UserProfile[] = [];

    for (const chunk of userChunks) {
      console.log("Fetching user profiles by batch", chunk);
      const q = query(usersCollection, where("userId", "in", chunk), where("createdBy", "==", adminUserId));
      const querySnapshot = await getDocs(q);
      querySnapshot.docs.forEach(doc => userProfiles.push({ ...doc.data(), id: doc.id } as UserProfile));
      console.log("Fetched user profiles by batch", userProfiles);
    }

    return userProfiles;
  } catch (error) {
    console.error('Failed to fetch user profiles by batch', error);
    throw new Error('Failed to fetch user profiles by batch');
  }
}

export const fetchUserProfileByEmail = async (email: string): Promise<UserProfile | null> => {
  console.log("Fetching user profile by email address", email);
  try {
    const auth = getAuth();
    const user = auth.currentUser;
    if (!user) throw new Error('No authenticated user found');

    const usersCollection = collection(db, 'UserProfiles');
    const q = query(usersCollection, where("email", "==", email), where("createdBy", "==", user.uid));
    const querySnapshot = await getDocs(q);
  
    if (!querySnapshot.empty) {
      const userDoc = querySnapshot.docs[0];
      return { ...userDoc.data(), id: userDoc.id } as UserProfile;
    } else {
      return null;
    }
  } catch (error) {
    console.error('Failed to fetch user profile by email address', error);
    throw new Error('Failed to fetch user profile');
  }
};

export const fetchProfilesUserIsDelegateFor = async (): Promise<UserProfile[]> => {
  try {
    const userEmail = getCurrentUserEmail();
    const usersCollection = collection(db, 'UserProfiles');
    const q = query(usersCollection, where("delegates", "array-contains", userEmail));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }) as UserProfile);
  } catch (error) {
    console.error('Failed to fetch profiles user is delegate for', error);
    throw new Error('Failed to fetch profiles user is delegate for');
  }
}

export const updateDetailsForUserProfile = async (userId: string, displayName: string, email: string, mass: number, massUnit: string): Promise<void> => {
  try {
    const userDocRef = doc(db, 'UserProfiles', userId);
    await updateDoc(userDocRef, {
      displayName,
      email,
      mass,
      massUnit,
    });
  } catch (error) {
    console.error('Failed to update user profile', error);
    throw new Error('Failed to update user profile');
  }
}

export const updateUserProfileWithImage = async (userId: string, imageUrl: string) => {
  try {
    const userDocRef = doc(db, 'UserProfiles', userId);
    await updateDoc(userDocRef, { profileImageURL: imageUrl });
    return true;
  }
  catch (error) {
    console.error('Failed to update user profile with image', error);
    throw new Error('Failed to update user profile with image');
  }
};

export const removeTeamIdFromUserProfile = async (userId: string, teamId: string): Promise<void> => {
  try {
    const userDocRef = doc(db, 'UserProfiles', userId);
    const userDoc = await getDoc(userDocRef);
    if (!userDoc.exists()) {
      console.error('User not found');
      throw new Error('User not found');
    }

    const userData = userDoc.data();
    if (!userData) {
      console.error('No data available for the user');
      throw new Error('No data available for the user');
    }

    if (!userData.teams || !Array.isArray(userData.teams)) {
      console.error('Invalid or undefined teams array');
      throw new Error('Invalid or undefined teams array');
    }

    const updatedTeams = userData.teams.filter((team: string) => team !== teamId);
    await updateDoc(userDocRef, { teams: updatedTeams });
  } catch (error) {
    console.error('Failed to remove team ID from user profile', error);
    throw new Error('Failed to remove team ID from user profile');
  }
}

export const createNewUserProfile = async (displayName: string, email: string, mass: number, massUnit: string, teamId: string): Promise<string> => {
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) throw new Error('No authenticated user found');

  const userProfileRef = doc(collection(db, 'UserProfiles'));
  const userId = userProfileRef.id;
  const creatorEmail = getCurrentUserEmail();

  let newUserProfile = {
    userId,
    displayName,
    email: email,
    mass: mass || 68,
    massUnit: massUnit || "Kg",
    createdAt: Timestamp.now(),
    createdBy: user.uid,
    teams: teamId ? [teamId] : [],
    delegates: creatorEmail ? [creatorEmail] : [],
    invitedBy: teamId ? user.uid : null,
    profileImageURL: null
  };

  try {
    const existingUserProfile = await fetchUserProfileByEmail(email);
    if (existingUserProfile) return existingUserProfile.id;

    await setDoc(userProfileRef, newUserProfile);

    console.log('User profile created with ID: ', userId, newUserProfile);
    return userId;
  } catch (error) {
    console.error('Failed to create user profile', error);
    throw new Error('Failed to create user profile');
  }
};